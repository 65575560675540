import React from "react";
import moment from "moment";
import {
  formateStringTypeNumber,
  getCurrencySymbol,
  noop,
  stringToFixedNumber,
} from "../../../../../Utils/commonUtils";
import { getNAObjectForCard } from "../cudosUtil";

import FusionChart from "../../../Common/fusionChart";
import { ReactComponent as NoDataDoughnut } from "../../../../../Assets/images/NoDataDoughnut.svg";
import { doughnutPaletteColors, SUMMARY_CARDS_CONSTANT } from "./constant";
import { graphTableLastHeader } from "../Database/constant";

export const getDoughnutChart = (graphData = {}) =>
  graphData?.graphData?.data?.some((item) => item?.value !== 0) ? (
    <FusionChart
      graphData={graphData?.graphData}
      chartType={graphData?.type}
      width={84}
      height={84}
      pieRadius={42}
      doughnutRadius={32}
      palettecolors={doughnutPaletteColors}
    />
  ) : (
    <div>
      <NoDataDoughnut className="h-[84px] w-[84px]" />
    </div>
  );

export const formatValue = (value, formatter) => (value ? formatter(value) : "- -");

export const manipulateGraphData = (data = []) => {
  const graphDataManipulationCategories = data?.categories?.map((item) => ({
    label: item,
  }));

  const graphDataManipulationData = data?.seriesList?.map((item) => {
    const dataArray = item?.seriesValues?.map((internalItem) => ({ value: internalItem }));
    const returnObj = {
      seriesname: item.seriesName,
      lineColor: item.lineColor, // Line color for "Last Year"
      anchorBgColor: item.anchorBgColor, // Color for anchor points and legend marker
      anchorBorderColor: item.anchorBorderColor,
      data: dataArray,
    };
    return returnObj;
  });

  const GraphObj = {
    category: graphDataManipulationCategories,
    dataset: graphDataManipulationData,
  };
  return GraphObj;
};

const manipulateTableData = (data = {}) => {
  const tableData = data?.customTableDto?.tableRows?.map((item) => {
    const tableObj = {};
    Object.keys(item.columnValuesForRow || {}).forEach((internalItem) => {
      const value = item?.columnValuesForRow[internalItem];
      tableObj[internalItem] =
        typeof value?.cellValue === "number" ? stringToFixedNumber(value?.cellValue, 2) : value;
      return tableObj[internalItem] || "";
    });
    tableObj.rowName = item.rowName;
    tableObj.total =
      typeof item?.totalValueForRow === "number"
        ? stringToFixedNumber(item?.totalValueForRow, 2)
        : item?.totalValueForRow || "";
    return tableObj;
  });
  return tableData;
};

const manipulateHeadersDataNew = (graph = {}) => {
  const headers =
    graph?.data?.customTableDto?.columnNames?.map((item) => ({
      key: item,
      value: item,
    })) || [];
  headers.unshift(graph?.firstHeader);
  headers.push(graphTableLastHeader());
  return headers;
};

const manipulateFooterDataNew = (graph = {}) => {
  const updatedTotal = {};
  const prefix = !(graph?.active !== "COST" && graph?.name === "costByTop5Usage")
    ? getCurrencySymbol()
    : "";
  Object.keys(graph?.customTableDto?.columnWiseSum || {}).forEach((item) => {
    updatedTotal[item] = `${formateStringTypeNumber(
      graph?.customTableDto?.columnWiseSum?.[item],
      !!prefix
    )}`;
  });
  const footer = { ...updatedTotal, rowName: "Total" };
  return footer;
};

export const getFooterData = (data, headers = []) => {
  const footerObj = {};
  headers.forEach((header, index) => {
    if (index === 0) footerObj[header.key] = "Total";
    // else if (index === headers.length - 1)
    else footerObj[header?.key] = data?.data?.data?.totalCosts?.[header.key] || "";
  });
  return footerObj;
};

export const getRouteUrlWithoutSelected = (navigate = noop, accountDate = {}, baseUrl = "") => {
  const data = new URLSearchParams(window.location.search);
  const query = localStorage.getItem("query");

  // Check if "month" and "year" query params exist in the current URL
  if (data.get("month") && data.get("year")) {
    const updatedUrl = `month=${data.get("month")}&year=${data.get("year")}`;

    setTimeout(() => {
      navigate(`${baseUrl}?${updatedUrl}`);
    }, 50);
  } else {
    // Default "month" and "year" values based on `accountEndDate`
    const defaultMonth = moment(accountDate?.accountEndDate).format("MM");
    const defaultYear = moment(accountDate?.accountEndDate).format("YYYY");

    let updatedUrl = query ? `${query}` : `?month=${defaultMonth}&year=${defaultYear}`;

    // need to remove hard code check... Check if the baseUrl contains "dashboard/database"
    if (baseUrl.includes("dashboard/database")) {
      updatedUrl = `?selected=elasticache&month=${defaultMonth}&year=${defaultYear}`;
    }

    setTimeout(() => {
      navigate(`${baseUrl}${updatedUrl}`, {
        month: defaultMonth,
        year: defaultYear,
      });
    }, 50);
  }
};

export const getGraphDataNew = (graph = {}) => {
  const graphData1 = manipulateGraphData(graph.data?.lineChart);
  const graphtableData = manipulateTableData(graph.data);
  const graphTableHeader = manipulateHeadersDataNew(graph);
  const graphTableFooter = manipulateFooterDataNew(graph.data);
  return {
    ...graph,
    graphData: graphData1,
    graphtableData,
    graphTableHeader,
    graphTableFooter,
    showLabels: graph.showLabels || "0",
    trendsHeaderArray: graph.trendHeaderData,
    list: graph.list || [],
    calendarType: graph?.calendarType,
  };
};

export const getMonthCardsData = (cudosCardData = {}) => {
  const data = new URLSearchParams(window.location.search);
  const forecastData =
    !!cudosCardData &&
    cudosCardData?.analyticsTrendDto?.list?.map((individualOption) => ({
      month: moment(individualOption?.monthYear, "MMM-YYYY").format("MMM YYYY"),
      amount: individualOption?.currentMonthValue ? individualOption?.currentMonthValue : 0,
      percentage: individualOption?.isForecastPresent
        ? cudosCardData?.recommendationSummary?.percentageTrend || 0
        : individualOption?.percentageTrend || 0,
      differenceBetweenValues: individualOption?.differenceBetweenValues
        ? individualOption?.differenceBetweenValues
        : 0,
      forecastValue: individualOption?.forecastValue || 0,
      isPercentageBoxVisible: individualOption?.isPercentageBoxVisible,
      isForecastPresent: individualOption?.isForecastPresent,
    }));

  for (let i = forecastData.length; i < 3; i += 1) {
    forecastData.unshift(
      getNAObjectForCard(
        moment(`${data.get("month")} ${data.get("year")}`, "MM YYYY").subtract(i, "months")
      )
    );
  }
  return forecastData;
};

// export const getCudosAvailableTabs = (tabs = []) => {
//   const availableTabs = tabs?.filter((tab) => tab?.id?.includes("DATABASE"));
//   const filteredArray = [
//     "EC2",
//     "RDS",
//     "Redshift",
//     "ElastiCache",
//     "OpenSearch",
//     "SavingsPlan",
//   ].filter((item) =>
//     availableTabs.some((obj) => obj.props.pageTitle.toLowerCase() === item.toLowerCase())
//   );
//   return filteredArray;
// };

export const manipulateSummaryCardsData = (data, service) => {
  const updatedSummaryCards = SUMMARY_CARDS_CONSTANT[service]?.map((item) => {
    const updatedValues = item?.value?.map((subItem) => {
      const responseData = data?.[item?.key]?.[subItem?.key];

      if (responseData) {
        return {
          ...subItem,
          value: responseData?.value || "--",
          link: responseData?.link,
        };
      }

      return subItem;
    });

    return {
      ...item,
      value: updatedValues,
    };
  });

  return updatedSummaryCards;
};
