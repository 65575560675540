import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SELECTED_DASHBOARD_ENUM } from "./configuration";
import { isDateValid } from "../../../../../Utils/commonUtils";
import { getRouteUrlWithoutSelected } from "../helper/utils";
import { getRouteUrl } from "../../CostBreakup/costbreakupUtils";
import { getTabs } from "../../Reservations/ReservedInstances/helper/constants";

const useCudos = ({ pathname }) => {
  // State for dashboard name
  const url = new URLSearchParams();
  const selectedServiceFromUrl = url.get("selected")?.toLowerCase() || undefined;
  const pathArray = window.location.pathname?.split("/");
  const dashboardName = pathArray[pathArray.length - 1];
  const tabs = getTabs()?.map((item) => item.title);

  const getInitDasboardSelected = () => {
    if (tabs.length) {
      if (selectedServiceFromUrl) return selectedServiceFromUrl;
      return tabs[0]?.toLowerCase();
    }
    return dashboardName?.toLowerCase();
  };
  const navigate = useNavigate();
  const accountDate = useSelector((state) => state.AccountDateReducer) || {};

  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(getInitDasboardSelected());
  const [activeGranularity, setActiveGranularity] = useState("Monthly");
  const [activeChartType, setActiveChartType] = useState("stackedcolumn2d");
  const [dashboardConfig, setDashboardConfig] = useState(
    SELECTED_DASHBOARD_ENUM[getInitDasboardSelected()]?.config
  );
  const [granularityLoader, setGranularityLoader] = useState(false);

  const getApiFun = (configObj, key, granularity, tab) =>
    configObj[key]?.serviceCall(
      tab,
      key,
      configObj[key]?.isGranularityApplied ? granularity : undefined
    );

  const apiHandler = async (
    granularity = "MONTHLY",
    isGranularityUpdated = false,
    dashboardConfiguration = dashboardConfig,
    tab = selected
  ) => {
    if (isGranularityUpdated) setGranularityLoader(true);
    else setIsLoading(true);
    try {
      const configObj = cloneDeep(dashboardConfiguration?.config);
      if (configObj) {
        const apiArr = [];
        Object.keys(configObj || {}).forEach((key) => {
          if (isGranularityUpdated) {
            if (configObj[key]?.isGranularityApplied) {
              apiArr.push(getApiFun(configObj, key, granularity, tab));
            }
          } else {
            apiArr.push(getApiFun(configObj, key, granularity, tab));
          }
        });

        const response = await Promise.all(apiArr);
        response.forEach((res) => {
          if (configObj[res.name]) {
            configObj[res.name].data = res?.data;
          }
        });
        setDashboardConfig({ config: configObj });
      }
    } finally {
      setIsLoading(false);
      setGranularityLoader(false);
    }
  };

  const tabClicked = (e, selectedTab) => {
    if (!e.ctrlKey && selectedTab !== selected) {
      setSelected(selectedTab);
      apiHandler(activeGranularity, false, dashboardConfig, selectedTab);
    }
  };
  const onChartTypeChange = async (chartType) => {
    setGranularityLoader(true);
    const updatedObjects = [];
    Object?.keys(dashboardConfig?.config)?.forEach((ele) => {
      if (dashboardConfig.config[ele]?.isGranularityApplied) {
        updatedObjects?.push({ ...dashboardConfig.config[ele], type: chartType });
      }
    });
    const dashboardConfigClone = cloneDeep(dashboardConfig.config);
    updatedObjects?.forEach((ele) => {
      dashboardConfigClone[ele.name] = ele;
    });

    setDashboardConfig({ config: dashboardConfigClone });
    setActiveChartType(chartType);
    setTimeout(() => setGranularityLoader(false), 1000);
  };

  const onGranulityChange = (item) => {
    apiHandler(item?.toUpperCase(), true, dashboardConfig, selected);
    setActiveGranularity(item);
  };

  const selectedDate = async () => {
    if (isDateValid()) {
      apiHandler(activeGranularity.toUpperCase());
    }
  };

  const updateRoutes = () => {
    const tab = tabs[0]?.toLocaleLowerCase();
    if (tabs.length) {
      getRouteUrl(navigate, accountDate, tab, pathname);
    } else {
      getRouteUrlWithoutSelected(navigate, accountDate, pathname);
    }
  };

  useEffect(() => {
    const selectedDashbaord = getInitDasboardSelected();
    const config = cloneDeep(SELECTED_DASHBOARD_ENUM[selectedDashbaord]?.config);
    setIsLoading(true);
    setSelected(selectedDashbaord);
    setActiveGranularity("Monthly");
    setActiveChartType("stackedcolumn2d");
    setDashboardConfig(config);
    updateRoutes();
    apiHandler("MONTHLY", false, config, selectedDashbaord);
  }, [pathname]);

  return {
    isLoading,
    activeGranularity,
    dashboardConfig: dashboardConfig?.config,
    selectedService: dashboardName,
    onGranulityChange,
    activeChartType,
    onChartTypeChange,
    selectedDate,
    selected,
    setSelected,
    tabClicked,
    granularityLoader,
    tabs,
    accountDate,
  };
};

export default useCudos;
