import {
  exportCostByCluster,
  exportCostByInstanceType,
  exportCostByNamespace,
  exportCostByPurchaseType,
  exportCostByRegion,
  exportTop20CostUsageTableData,
  getCostByCluster,
  getCostByInstanceType,
  getCostByNameSpace,
  getCostByPurchase,
  getSummaryCardsData,
  getTop20InstanceTableData,
  getCudosDatabaseCards,
  getCostByRegion,
  getTotalAccountsData,
  exportCostByAccount,
  getCostEngine,
  exportCostByEngine,
  getRICoverageData,
  getCostByPricingType,
  getRecommendationSummary,
  exportCostByPricingType,
} from "./service";
import MonthCards from "../Elasticache/components/MonthCards";
import ElasticSummaryCards from "../Elasticache/components/SummaryCards";
import SummaryCards from "../EKS/component/SummaryCards";
import {
  PALLETE_COLORS_CUDOS,
  TOP_20_INSTANCE_HEADERS,
  TOP_20_INSTANCE_HEADERS_ELASTICACHE,
} from "../helper/constant";
import Routes from "../../../../../Config/Routes";
import TotalRiCoverageCost from "../Elasticache/components/TotalRICoverage";
import RecommendationSummary from "../Elasticache/components/RecommendationSummary";

export const commonProps = {
  tabs: false,
  hideScroll: true,
  showLabels: "1",
  showMoreIcon: true,
  showGraphExpandIcon: true,
  xaxislinecolor: "#EDEFF0",
  yaxislinecolor: "#EDEFF0",
  divLineDashed: "0",
  divLineColor: "#D7D7D7",
  numVDivLines: "0",
  legendIconSides: 0,
  labelFontColor: "#2B2B2B",
  labelFontSize: 9,
  rotateLabels: 1,
  yAxisValueFontColor: "#2B2B2B",
  yAxisValueFontSize: 9,
  height: 326,
  labelStep: "0",
  anchorRadius: "2",
  palettecolors: PALLETE_COLORS_CUDOS.join(","),
};

const COST_BY_ACCOUNTS = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Accounts",
  name: "costByAccounts",
  serviceCall: getTotalAccountsData,
  exportServiceCall: exportCostByAccount,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Account Name",
    align: "Left",
  },
  ...commonProps,
};

const COST_BY_ENGINES = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Engine",
  name: "costByEngines",
  serviceCall: getCostEngine,
  exportServiceCall: exportCostByEngine,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Engine",
    align: "Left",
  },
  ...commonProps,
};

const COST_BY_REGION = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Regions",
  name: "costByRegions",
  serviceCall: getCostByRegion,
  exportServiceCall: exportCostByRegion,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
};

const COST_BY_INSTANCE_TYPE = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Instance Type",
  serviceCall: getCostByInstanceType,
  exportServiceCall: exportCostByInstanceType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Instance Type",
    align: "Left",
  },
  name: "costByInstanceType",
  ...commonProps,
};

const COST_BY_PRICING_TYPE = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Pricing Type",
  serviceCall: getCostByPricingType,
  exportServiceCall: exportCostByPricingType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Pricing Type",
    align: "Left",
  },
  name: "costByPricingType",
  ...commonProps,
};

const COST_BY_PURCHASE_TYPE = {
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Purchase Type",
  name: "costByPurchaseType",
  serviceCall: getCostByPurchase,
  exportServiceCall: exportCostByPurchaseType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
};

const TOP_20_TABLE_CONSTANT = {
  componentType: "table",
  heading: "Top 20 Node Instance Usage by Total Cost",
  headers: TOP_20_INSTANCE_HEADERS,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const TOP_20_TABLE_CONSTANT_ELASTICACHE = {
  componentType: "table",
  headers: TOP_20_INSTANCE_HEADERS_ELASTICACHE,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const EKS_CONSTANT = {
  config: {
    monthCards: {
      componentType: "custom",
      component: MonthCards,
      className: "w-[35%]",
      serviceCall: getCudosDatabaseCards,
    },
    summaryCards: {
      componentType: "custom",
      component: SummaryCards,
      className: "w-[48%]",
      serviceCall: getSummaryCardsData,
    },
    recommendationSummary: {
      componentType: "custom",
      component: RecommendationSummary,
      className: "w-[calc(100%-83%-8px)]",
      serviceCall: getRecommendationSummary,
    },
    costByClusters: {
      componentType: "graph",
      type: "stackedcolumn2d",
      heading: "Cost by Clusters",
      name: "costByClusters",
      serviceCall: getCostByCluster,
      exportServiceCall: exportCostByCluster,
      isGranularityApplied: true,
      firstHeader: {
        key: "rowName",
        value: "Cluster Name",
        align: "Left",
      },
      wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...commonProps,
    },
    costByNamespace: {
      componentType: "graph",
      type: "stackedcolumn2d",
      heading: "Cost by Namespace",
      name: "costByNamespace",
      serviceCall: getCostByNameSpace,
      exportServiceCall: exportCostByNamespace,
      isGranularityApplied: true,
      firstHeader: {
        key: "rowName",
        value: "Namespace",
        align: "Left",
      },
      wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...commonProps,
    },
    costByRegions: {
      wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...COST_BY_REGION,
    },
    costByInstanceType: {
      wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...COST_BY_INSTANCE_TYPE,
    },
    costByPurchaseType: {
      wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...COST_BY_PURCHASE_TYPE,
    },
    Top20InstanceTable: {
      wrapperClass: "w-[49.8%] !mt-[10px] !mb-[0px] graph-wrapper-style",
      ...TOP_20_TABLE_CONSTANT,
    },
  },
};

const ELASTICACHE_CONSTANT = {
  config: {
    monthCards: {
      componentType: "custom",
      component: MonthCards,
      className: "w-[45%]",
      serviceCall: getCudosDatabaseCards,
    },
    summaryCards: {
      componentType: "custom",
      component: ElasticSummaryCards,
      className: "w-[35%]",
      serviceCall: getSummaryCardsData,
    },
    recommendationSummary: {
      componentType: "custom",
      component: RecommendationSummary,
      className: "w-[calc(20%-20px)]",
      serviceCall: getRecommendationSummary,
    },
    costByAccounts: {
      wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(54%-4px)]",
      ...COST_BY_ACCOUNTS,
    },
    costByRegions: {
      wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[46%]",
      ...COST_BY_REGION,
    },
    costByEngines: {
      wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(40%-4px)]",
      ...COST_BY_ENGINES,
    },
    costByInstanceType: {
      wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[60%]",
      ...COST_BY_INSTANCE_TYPE,
    },
    RICoverageCost: {
      componentType: "custom",
      component: TotalRiCoverageCost,
      heading: "RI Coverage in Cost",
      name: "RICoverageCost",
      serviceCall: getRICoverageData,
    },
    costByPricingType: {
      wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[calc(55%-4px)]",
      ...COST_BY_PRICING_TYPE,
    },
    Top20InstanceTable: TOP_20_TABLE_CONSTANT_ELASTICACHE,
  },
};

export const SELECTED_DASHBOARD_ENUM = {
  eks: {
    name: "EKS",
    route: Routes.ECOST_BREAKUP_EKSS,
    config: EKS_CONSTANT,
  },
  elasticache: {
    name: "ELASTICACHE",
    route: Routes.COST_BREAKUP_DATABASE,
    config: ELASTICACHE_CONSTANT,
  },
  rds: {
    name: "RDS",
    route: Routes.COST_BREAKUP_DATABASE,
    config: ELASTICACHE_CONSTANT,
  },
};
