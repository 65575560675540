import React from "react";
import "./style.scss";

function SummaryCards(props) {
  const { summaryCardsData = [], className = "" } = props;

  const calculateWidthForDivider = (arr) => `w-[calc(100%/${arr?.length})]`;

  return (
    <div className={`flex gap-[5px] ${className}`}>
      {summaryCardsData?.map((item) => (
        <div className="ck-cudos-months-cards">
          <div className="graviton-heading">{item?.label}</div>
          <div className="flex pt-[15px] pb-[20px] px-[10px] gap-[10px]">
            {item?.value?.map((_, index, arr) => (
              <div className={index < arr.length - 1 && `divider ${calculateWidthForDivider(arr)}`}>
                <div className="graviton">{_?.label}</div>
                <div className="graviton-amount">{_?.value ?? "--"}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default SummaryCards;
