import React from "react";
import "./style.scss";
import { numberWithCommas } from "../../../../../Utils/commonUtils";

export default function UtilizationCardV2(props) {
  const { header, percentage = 0, progressbar, cost, cardIcon, customClass = "" } = props;

  return (
    <div className="UtilizationCard_V2">
      <div className="NewCardIcon">
        <span className={`utilizationIconWrapper ${customClass}`}>{cardIcon}</span>
      </div>
      <div className="cardSubDetails">
        <h4 className="UtilizationCard_sub_heading" id={header?.split(" ").join("_")}>
          {progressbar ? `${percentage?.toFixed(0)}%` : `$${numberWithCommas(cost)}`}
        </h4>
        <h5 className="UtilizationCard_heading">{header}</h5>
      </div>
    </div>
  );
}
