import React from "react";
import ForecastCard from "../../../Assets/forecast.svg";
import { numberWithCommasandDecimal } from "../../../../../../../Utils/commonUtils";
import { ReactComponent as ArrowDownGreen } from "../../../Assets/arrow-down-green-filled.svg";
import CardImage from "../../../Assets/monthCards.svg";
import { ReactComponent as CardTopArrow } from "../../../../../../../Assets/icons/others/card-arrow-top.svg";
import "./style.scss";

function MonthCards(props) {
  const { cardsData = [], className = "" } = props;

  const renderHeading = (data = "") => (
    <div className="text-[12px] font-medium text-[#6F7584] mb-[7px]">{data}</div>
  );

  const renderAmount = (data = 0) => (
    <div className="text-[17px] font-medium text-[#000000] mb-[5px] leading-none">
      {numberWithCommasandDecimal(data, 0)}
    </div>
  );

  const renderTrendPercentage = (percentage, differenceBetweenValues) => (
    <div>
      {differenceBetweenValues > 0 ? (
        <div className="flex items-center text-[10px] text-[#E92013] gap-[2px]">
          <CardTopArrow className="h-[7px] w-[6px]" />
          <span className="">{percentage?.toFixed(0)}%</span>
        </div>
      ) : (
        <div className="flex items-center text-[10px] text-[#32B556] gap-[2px]">
          <ArrowDownGreen className="h-[7px] w-[6px]" />
          <span className="">{percentage?.toFixed(0)}%</span>
        </div>
      )}
    </div>
  );

  const renderTrendCard = (data) => (
    <>
      <span className="text-[10px] text-[#4B5157]">
        {data.differenceBetweenValues >= 0 ? "+" : "-"}
        {numberWithCommasandDecimal(Math.abs(data.differenceBetweenValues), 0)}
      </span>
      {renderTrendPercentage(data.percentage, data.differenceBetweenValues)}
    </>
  );

  const renderForecastData = (data) => (
    <div
      style={{
        backgroundImage: `url(${ForecastCard})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="rounded-[5px] mt-[-6px] w-[55%]"
    >
      <div className="py-[5px] px-[12px]">
        {renderHeading("FORECAST")}
        {renderAmount(data.forecastValue)}
        <div className="flex gap-[10px] rounded-[5px] leading-5 px-[2px] bg-[#fff] w-max text-[12px] trend-card">
          {renderTrendCard(data)}
        </div>
      </div>
    </div>
  );

  const renderCardsData = (data) =>
    !data.isForecastPresent ? (
      <div>
        {renderHeading(data?.month?.toUpperCase()) || "--"}
        {renderAmount(data?.amount ?? "--")}
        <div className="flex gap-[10px] rounded-[5px] py-[4px] px-[10px] bg-[#F5F9FD] w-max text-[12px] trend-card">
          {renderTrendCard(data)}
        </div>
      </div>
    ) : (
      <div className="flex justify-between">
        <div className="w-[40%]">
          {renderHeading(data?.month?.toUpperCase()) || "--"}
          {renderAmount(data?.amount ?? "--")}
        </div>
        {renderForecastData(data)}
      </div>
    );

  return (
    <div className={`ck-cudos-month-cards-wrapper ${className}`}>
      {cardsData?.map((card) => (
        <div
          className={`ck-cudos-months-cards ${card.isForecastPresent ? "forcasted" : ""}`}
          style={{
            backgroundImage: `url(${CardImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={`px-[12px] pt-[17px] ${!card.isForecastPresent ? "pb-[13px]" : "pb-[9px]"}`}
          >
            {card.isNABlock ? (
              <div>
                {renderHeading(card.month?.toUpperCase())}
                <span>--</span>
              </div>
            ) : (
              renderCardsData(card)
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MonthCards;
