import { getPayload, getTotalAccountsPayload } from "../../CostBreakup/costbreakupUtils";
import { manipulateApiResponse } from "../Database/util";
import commonService from "../../../../../Services/commonService";
import { getMonthCardsData, manipulateSummaryCardsData } from "../helper/utils";
import { RICoverageCost } from "../Database/constant";
import { downloadExcel } from "../../../../../Utils/commonUtils";

export const getSummaryCardsData = async (selected, name = "") => {
  try {
    const res = await commonService.getSummaryCards(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return {
      name,
      data: manipulateSummaryCardsData(res?.data?.data, selected) || [],
    };
  } catch (error) {
    return error;
  }
};

export const getCudosDatabaseCards = async (
  selected,
  name = "",
  manipulateCardsData = getMonthCardsData
) => {
  try {
    const payload = getPayload(selected?.toUpperCase());
    const cudosCardsData = await commonService.getCudosDatabaseCards(payload);
    return { name, data: manipulateCardsData(cudosCardsData?.data?.data) };
  } catch (error) {
    return error;
  }
};

export const getRecommendationSummary = async (selected, name = "") => {
  try {
    const payload = getPayload(selected?.toUpperCase());
    const cudosCardsData = await commonService.getRecommendationSummary(payload);
    return { name, data: cudosCardsData?.data?.data };
  } catch (error) {
    return error;
  }
};

export const getCostByRegion = async (selected, name = "", reportGranularity = "MONTHLY") => {
  try {
    const regionData = await commonService.getCostRegion(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByInstanceType = async (
  selected = "",
  name = "",
  reportGranularity = "MONTHLY"
) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    const instanceTypeData = await commonService.getCostByInstanceTypeDatabase(graphPayload);
    const data = manipulateApiResponse(instanceTypeData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByCluster = async (selected = "", name = "", reportGranularity = "MONTHLY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostCluster(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByNameSpace = async (
  selected = "",
  name = "",
  reportGranularity = "MONTHLY"
) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByNameSpace(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByPurchase = async (
  selected = "",
  name = "",
  reportGranularity = "MONTHLY"
) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByPurchase(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTop20InstanceTableData = async (selected, name = "") => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
    });
    const res = await commonService.getTop20CostUsage(payload);
    return { name, data: res };
  } catch (error) {
    return error;
  }
};

export const exportCostByCluster = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByCluster(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByNamespace = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByNamespace(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByRegion = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByRegion(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByInstanceType = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByInstanceType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByPurchaseType = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByPurchaseTypeEKS(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTop20CostUsageTableData = async (selected = "", setExcelLoading = () => {}) => {
  setExcelLoading(true);
  try {
    const res = await commonService.exportTop20CostUsageTableData(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    downloadExcel(res);
    return res;
  } catch (error) {
    return error;
  } finally {
    setExcelLoading(false);
  }
};

// export const EKS_SERVICE_CONSTANT = {
//   costByClusters: (service, granularity) => getCostByCluster(service, granularity),
//   costByNamespace: (service, granularity) => getCostByNameSpace(service, granularity),
//   costByRegions: (service, granularity) => getCostRegion(service, granularity),
//   costByInstanceType: (service, granularity) => getCostByInstanceType(service, granularity),
//   costByPurchaseType: (service, granularity) => getCostByPurchase(service, granularity),
// };

export const getCostEngine = async (selected = "", name = "", reportGranularity = "MONTHLY") => {
  try {
    const engineData = await commonService.getCostEngine(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    const data = manipulateApiResponse(engineData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByEngine = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    service: selected?.toUpperCase(),
    reportGranularity,
  });
  try {
    const res = await commonService.exportTotalCostByEngine(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getTotalAccountsData = async (selected, name = "", reportGranularity = "DAILY") => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getTotalAccountsData(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByAccount = async (selected = "", reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByAccount(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByPricingType = async (selected = "", reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByPricingType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getRICoverageData = async (selected = "", name = "") => {
  try {
    const res = await commonService.getRiCoverageUsage(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return {
      name,
      data: {
        stackedGraphData: res?.data?.data?.coverageList,
        graphData: {
          ...RICoverageCost,
          graphData: {
            data: [
              {
                label: "Reservation Covered Cost",
                value: res?.data?.data?.totalRdsReservationCost,
              },
              {
                label: "Remaining OnDemand Cost",
                value: res?.data?.data?.totalRdsOnDemandCost,
              },
            ],
          },
        },
        riCoverageData: res?.data?.data,
      },
    };
  } catch (error) {
    return error;
  }
};

export const getCostByPricingType = async (selected, name = "", reportGranularity = "MONTHLY") => {
  try {
    const regionData = await commonService.getCostByPricingType(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
