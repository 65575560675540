import React, { useEffect } from "react";
import withRouter from "../../../Common/WithRouter";
import useCudos from "./hooks/useCudos";
import Routes from "../../../../Config/Routes";
import CudosHeader from "./CudosHeader";
import ChartType from "./Components/ChartType";
import CudosTable from "./Components/Table";
import Graph from "./S3/graph/Graph";
import { TabsWrapper } from "../util";
import "./style.scss";
import { getFooterData, getGraphDataNew } from "./helper/utils";
import Loader from "../../../Common/Loader";
import PageTitle from "../../../Common/PageTitle";

function Cudos(props) {
  const {
    setPermission,
    accessList,
    documentTitle,
    pathArray,
    location: { pathname },
  } = props;
  const url = new URLSearchParams();
  const selectedServiceFromUrl = url.get("selected") || undefined;
  const {
    isLoading,
    activeGranularity,
    dashboardConfig,
    onGranulityChange,
    activeChartType,
    onChartTypeChange,
    selectedDate,
    selected,
    tabClicked,
    selectedService,
    granularityLoader,
    tabs,
    accountDate,
  } = useCudos({ pathname });

  const { accountEndDate, accountStartDate } = accountDate;

  useEffect(() => {
    setPermission(accessList);
  }, []);

  const renderSelectedService = () =>
    Object.keys(dashboardConfig || {})?.map((component) => {
      switch (dashboardConfig[component]?.componentType) {
        case "custom": {
          const Custom = dashboardConfig[component]?.component;
          return (
            <Custom
              cardsData={dashboardConfig[component]?.data}
              summaryCardsData={dashboardConfig[component]?.data}
              className={dashboardConfig[component]?.className}
            />
          );
        }
        case "graph":
          return (
            <Graph
              graph={getGraphDataNew(dashboardConfig[component])}
              id={dashboardConfig[component]?.name}
              key={dashboardConfig[component]?.name}
              show={dashboardConfig[component]?.show}
              showExcelBtn
              showAxisIcons={{
                xAxis: true,
                yAxis: true,
              }}
              wrapperClass={dashboardConfig[component]?.wrapperClass}
              onExcelDownload={dashboardConfig[component]?.exportServiceCall}
              excelLoading={dashboardConfig[component]?.excelLoading}
              showSortingOnTotal
              graphLoading={isLoading || granularityLoader}
              selectedService={selected}
              activeGranularity={activeGranularity}
            />
          );
        case "table":
          return (
            <CudosTable
              data={dashboardConfig[component]?.data}
              headers={dashboardConfig[component]?.headers}
              onExcelDownload={dashboardConfig[component]?.exportServiceCall}
              className="rds-table"
              footer={getFooterData(
                dashboardConfig[component]?.data,
                dashboardConfig[component]?.headers
              )}
              key={component}
              selected={selected}
            />
          );
        default:
          return null;
      }
    });

  return (
    <div className="ck-cudos-wrapper">
      {isLoading && <Loader popup />}
      <PageTitle pageTitle={documentTitle} />
      <CudosHeader
        breadcrumsData={pathArray?.map((item) => item.label)}
        headerName={documentTitle}
        selectedDateProp={selectedDate}
        accountEndDate={accountEndDate}
        accountStartDate={accountStartDate}
        isLoading={isLoading}
      />
      {selectedServiceFromUrl &&
        !isLoading &&
        TabsWrapper(isLoading, tabs, selected, tabClicked, Routes.COST_BREAKUP_DATABASE)}
      <ChartType
        onGranulityChange={onGranulityChange}
        onChartTypeChange={onChartTypeChange}
        activeChartType={activeChartType}
        activeGranulity={activeGranularity}
      />
      {!isLoading && (
        <div className={`cards-graph-table ${selectedService}`}>{renderSelectedService()}</div>
      )}
    </div>
  );
}

export default withRouter(Cudos);
