/* eslint-disable import/no-cycle */
/* eslint-disable max-params */
/* eslint-disable complexity */
import React from "react";
import Input from "./Components/input";
import DropDown from "./Components/DropDown";
import "./style.scss";
import formValidator from "./formValidator";
import RadioButtonGroup from "./Components/RadioButtonGroup";
import Text from "./Components/Text";
import UploadFile from "./Components/UploadFile";
import CustomerLinkedAccId from "../../CustomerLinkedAccId";
import Checkbox from "./Components/Checkbox";
import TextArea from "./Components/TextArea";
import Calendar from "./Components/Calendar/Calender";
import MonthCalendar from "./Components/MonthCalendar/index";
import InvoiceCustomer from "../../Dashboard/Invoice/generation/InvoiceCustomer";
import InvoiceDeliveryType from "../../Dashboard/Invoice/generation/InvoiceDeliveryType";
import PartnerEmailSettings from "../../Dashboard/PartnerSettings/PartnerEmailSettings";
// eslint-disable-next-line import/no-cycle
import SmtpSettings from "../../Dashboard/PartnerSettings/SmtpSettings";
import ReadOnlyDropdown from "./Components/ReadOnlyDropdown";
import CustomerInvoice from "../../Dashboard/Invoice/generation/customerInvoice";
import ToggleInput from "../../Dashboard/PartnerSettings/toggleInput";
import ReadOnlyView from "./Components/ReadOnlyText";
import { noop } from "../../../Utils/commonUtils";
import ListSelector from "./Components/ListSelector.js";
import MultiChipSelector from "../../Common/MultiChipSelector";
import AutoResizeTextbox from "./Components/AutoResizeTextbox";
import CheckboxGroup from "./Components/CheckboxGroup";
import TransferList from "../../TransferList";
import MultiHeaderForm from "./Components/MultiHeaderForm";
import EditableTable from "../../Dashboard/CustomerManagement/CreateCustomer/editableTable";
import ConfigureCommitment from "../../Dashboard/CustomerManagement/CreateCustomer/ConfigureCommitment";
import EditableTableWithTabs from "../../Dashboard/CustomerManagement/CreateCustomer/EditableTableWithTabs";
import InfoBox from "../../CK-Lens/Common/InfoBox";
import MultiAdditionComponent from "./Components/MultiAdditionComponent";
import { publish, subscribe, unsubscribe } from "../../../Utils/events";
import { isArray, isObject } from "../../Dashboard/CustomerManagement/CreateCustomer/customerUtil";
import CreditsOrdering from "./CreditOrdering/index";
import List from "./Components/List";
import PaginationTable from "./Components/PaginationListing";
import OptionInputField from "./Components/OptionInputField";
import RenderHTML from "./RenderHTML";
import SelectionTable from "./Components/SelectionTable";
import AutoCompleteInput from "./Components/AutoComplete";
import { combinedCheck } from "./helper";
import SectionedItems from "../../Billdesk/FormComponent/SectionedItems";
import DynamicTextFields from "./Components/DynamicTextFields";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      values: props.formValues,
      touched: {},
      initialValues: JSON.parse(JSON.stringify(props.formValues)),
    };
  }

  async componentDidMount() {
    const {
      props: { checkErrorOnLoadForFilledFields, formConfig = [], changeValidationStructure },
      state: { values },
    } = this;

    await this.handleSubmit(null, true);
    if (checkErrorOnLoadForFilledFields) {
      this.dependentFormValueErrors();
    }
    this.bindCustomEvent();

    let _formConfig = [...formConfig];

    if (changeValidationStructure && typeof changeValidationStructure === "function") {
      _formConfig = changeValidationStructure(formConfig);

      _formConfig?.forEach((config) => {
        if (config.triggerHandleChangeOnInit) {
          this.handleChangeEvent(
            {
              target: {
                value: values[config.name],
                name: config.name,
                customEvent: "custom:change",
              },
              eventType: "change",
            },
            config
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      props: { resetCount },
      resetFormValues,
    } = this;
    if (prevProps.resetCount !== resetCount) {
      resetFormValues();
    }
  }

  componentWillUnmount() {
    this.unbindCustomEvent();
  }

  dependentFormValueErrors = () => {
    const {
      state: { values = {}, errors = {}, touched },
      props: { formConfig = [], onInitHandler, isError, changeValidationStructure },
    } = this;

    let _formConfig = [...formConfig];

    if (changeValidationStructure && typeof changeValidationStructure === "function") {
      _formConfig = changeValidationStructure(formConfig);
    }
    const formErrors = formValidator(errors, values, _formConfig);

    Object.keys(formErrors?.fields)?.forEach((key) => {
      if (key in values) {
        touched[key] = true;
      }
    });
    this.setState(
      {
        values,
        touched,
        errors: { ...errors, ...formErrors.fields },
      },
      () => {
        const {
          state: { values: updatedValues, errors: updatedErrors },
        } = this;
        if (onInitHandler && typeof onInitHandler === "function") {
          onInitHandler(updatedValues, updatedErrors);
        }
      }
    );

    if (isError) {
      isError(Object.keys(formErrors.fields).length > 0 || Object.keys(errors).length > 0);
    }
  };

  resetFormValues = () => {
    const {
      state: { initialValues },
      props: { onResetHandler },
    } = this;

    this.setState(
      { values: JSON.parse(JSON.stringify(initialValues)), errors: {}, touched: {} },
      () => {
        this.handleSubmit(null, true);

        if (onResetHandler) {
          const {
            state: { values },
          } = this;

          onResetHandler(values);
        }
      }
    );
  };

  /**
   * will be called when submit button is clicked
   */
  handleSubmit = (event, disableSubmit, overRideErrors) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const {
      state: { values = {}, errors = {} },
      props: {
        handleSubmit,
        formConfig = [],
        onInitHandler,
        changeValidationStructure,
        showErrorOnSubmit,
      },
    } = this;

    let _formConfig = [...formConfig];

    if (changeValidationStructure && typeof changeValidationStructure === "function") {
      _formConfig = changeValidationStructure(formConfig);
    }

    const formErrors = formValidator(errors, values, _formConfig);

    if (
      !Object.keys(formErrors?.fields || {}).length &&
      typeof handleSubmit === "function" &&
      !disableSubmit
    ) {
      handleSubmit(values);
    } else {
      this.updateForm(
        formErrors.fields,
        values,
        null,
        (updatedValues, updatedErrors) => {
          if (onInitHandler && typeof onInitHandler === "function") {
            onInitHandler(updatedValues, updatedErrors);
          }
        },
        {},
        "",
        {},
        event?.type === "submit" && showErrorOnSubmit,
        overRideErrors
      );
    }
  };

  toggleDependency = (hide, dependency, handleErrors) => {
    const {
      props: { formConfig, isError },
      state: { errors, values },
    } = this;

    const target = formConfig.find((form) => form.name === dependency.id);
    if (target) {
      target.hidden = hide;
    }
    if (handleErrors) {
      if (!target.hidden) {
        if (!values[dependency.valueKey]) {
          errors[dependency.id] = "This field is required";
        }
      } else {
        delete errors[dependency.id];
      }
      this.setState({ errors });

      if (isError) {
        isError(Object.keys(errors).length);
      }
    }
  };

  handleDependecies = (formElement, value) => {
    if (formElement?.dependencies?.length) {
      formElement.dependencies?.forEach((dependency) => {
        if (dependency?.operator) {
          const valueToCheck = dependency.valueKey ? value[dependency.valueKey] : value;
          let condition = false;
          if (dependency?.operator === "equal") {
            condition = dependency.value !== valueToCheck;
          }
          if (dependency?.operator === "not equal") {
            condition = dependency?.value === valueToCheck;
          }
          this.toggleDependency(condition, dependency, true, value);
        } else {
          const valueToCheck = dependency.valueKey ? value[dependency.valueKey] : value;
          this.toggleDependency(dependency.value !== valueToCheck, dependency);
        }
      });
    }
  };

  resetDependentFormElements = (
    formElement,
    values,
    value,
    name,
    errors,
    overrideValue = false
  ) => {
    if (
      (values[name] || formElement[name] !== undefined || typeof values[name] === "boolean") &&
      (overrideValue ||
        JSON.stringify(value) !==
          JSON.stringify(values[name] || formElement?.type === "MultiAdditionComponent"))
    ) {
      const { resetValues = [] } = formElement;
      const valuesClone = values;
      const errorsClone = errors;
      const { formConfig = [], changeValidationStructure, onResetValuesHandler } = this.props;

      let _formConfig = [...formConfig];

      if (changeValidationStructure && typeof changeValidationStructure === "function") {
        _formConfig = changeValidationStructure(formConfig);
      }

      if (onResetValuesHandler && typeof onResetValuesHandler === "function" && !overrideValue) {
        const abort = onResetValuesHandler(formElement, value, values, _formConfig);
        if (abort) {
          return;
        }
      }

      resetValues.forEach((item) => {
        const targetElement = _formConfig.find((element) => element.name === item);

        if (targetElement?.enableResetClicked) {
          targetElement.resetCount = targetElement.resetCount + 1 || 1;
        }
        if (targetElement?.resetValues?.length) {
          this.resetDependentFormElements(
            targetElement,
            values,
            values[targetElement.name],
            targetElement.name,
            errors,
            true
          );
        }

        if (valuesClone[item] || targetElement?.hidden) {
          if (targetElement && Object.hasOwnProperty.call(targetElement, "resetDefaultValue")) {
            valuesClone[item] = targetElement?.resetDefaultValue;
          } else {
            valuesClone[item] = undefined;
          }

          if (targetElement?.validations?.rules?.required)
            errorsClone[item] = "This field is required";
        }
      });
    }
  };

  identifyChangeIndex = (oldValue, newValue) => {
    if (newValue && oldValue && newValue.length > oldValue.length) {
      // some input entered
      if (newValue.length - oldValue.length > 2) {
        // someone has added multiple values in a single instant
        // copy paste case
        return -1;
      }
      for (let i = 0; i < newValue.length; i += 1) {
        if (newValue[i] !== oldValue[i]) {
          return i;
        }
      }
      return undefined;
    }
    return undefined;
  };

  // eslint-disable-next-line max-statements
  handleChangeEvent = (event, formElement) => {
    if (event.preventDefault) event.preventDefault();

    const {
      state: { values = {}, errors },
      props: { onChangeHandler },
    } = this;

    const index = event.target.selectionStart - 1;
    const {
      target: { name, value },
    } = event;

    if (formElement?.enableDisable && !formElement?.executeEnableDisableLater) {
      this.enableDisableFields(formElement, value, name);
    }

    if (formElement?.resetValues) {
      this.resetDependentFormElements(formElement, values, value, name, errors);
    }

    if (formElement.updateFormValue) {
      let valuesToupdate = value;
      if (formElement?.modifyValues) {
        valuesToupdate = formElement?.modifyValues(value, values);
        formElement.updateFormValue.forEach((formEle) => {
          values[formEle?.id] = valuesToupdate;
        });
      } else {
        formElement.updateFormValue.forEach((formEle) => {
          values[formEle?.id] = {
            ...formEle.defaultValue,
            [formEle.keyToUpdate]: [...valuesToupdate],
          };
        });
      }
    }

    const changeIndex =
      typeof index === "number" ? index : this.identifyChangeIndex(values[name], value);

    values[name] = value;

    let formErrors = formValidator(errors, values, [formElement], changeIndex);

    /**
     * executing child validations
     */
    let childErrors = {};
    if (formElement.childValidations) {
      childErrors = formValidator(errors, values[name], formElement.childValidations);
      formErrors = { ...formErrors, ...childErrors };
      formElement.childValidations?.forEach((childValidations) => {
        const { name: childName } = childValidations;
        if (!childErrors?.fields?.[childName] && errors[childName]) {
          delete errors[childName];
        }
      });
    }
    /**
     * executing child validations
     */

    this.updateForm(
      formErrors.fields,
      values,
      name,
      (updatedValues, updatedErrors) => {
        if (onChangeHandler && typeof onChangeHandler === "function") {
          onChangeHandler(event, updatedValues, updatedErrors, formElement);
        }
      },
      formElement,
      formErrors.ruleKey,
      event
    );

    this.handleDependecies(formElement, value);
    if (formElement?.revalidation) {
      setTimeout(() => {
        this.handleSubmit(null, true, true);
      }, 0);
    }
  };

  checkCustomEvent = (event, formElement) => {
    const customEvent = formElement?.customEvent;
    if (
      event?.eventType === "blur" ||
      (formElement.type === "select" && event?.eventType !== "change") ||
      !customEvent ||
      !customEvent?.active
    ) {
      return false;
    }
    let currentValue = event?.target?.value;
    let customEventComparisionValue = customEvent?.value;
    if (
      isObject(customEventComparisionValue) &&
      Object.prototype.hasOwnProperty.call(customEventComparisionValue, "comparisonValue")
    ) {
      const key = customEventComparisionValue?.key;
      customEventComparisionValue = customEventComparisionValue?.comparisonValue;
      if (Array.isArray(currentValue)) {
        currentValue = currentValue.every((_) => _[formElement.enableDisable.valueKey]);
      } else {
        currentValue = currentValue?.[key];
      }
    }
    return customEventComparisionValue === currentValue;
  };

  /**
   * Change event on form, will be fired whenever
   * an element on the form is changed.
   * It will track the form values as well as errors
   */
  handleChange = (event, formElement) => {
    if (this.checkCustomEvent(event, formElement)) {
      publish(formElement?.customEvent?.name, {
        sourceEvent: event,
        sourceData: {
          formElement,
        },
      });
      return;
    }
    this.handleChangeEvent(event, formElement);
  };

  enableDisableFields = (formElement, value, name, formErrors) => {
    const {
      state: { values },
    } = this;

    if (formElement.type === "text") {
      // for inputType=text - it can be filled or empty
      // right now created validations for filled and empty state
      const configKey = value !== "" ? "filled" : value;

      this.toggleEnableDisable(
        [configKey],
        formElement,
        formErrors?.fields?.[formElement.name] ? true : undefined
      );
    } else if (
      formElement.type === "select" &&
      Object.hasOwnProperty.call(value, formElement.enableDisable.valueKey)
    ) {
      this.toggleEnableDisable(
        [formElement.enableDisable.valueKey],
        formElement,
        !value[formElement.enableDisable.valueKey]
      );
    } else if (formElement.type === "checkbox") {
      this.toggleEnableDisable([value], formElement, undefined);
    } else if (formElement.type === "invoice-customer-input-type") {
      const isEnabled = combinedCheck(formElement, value, values);
      this.toggleEnableDisable([formElement.enableDisable.valueKey], formElement, !isEnabled);
    } else if (value?.length) {
      // below code is for dropdown
      // Todo :: instead of length add type check
      this.toggleEnableDisable(value, formElement, true);
    } else {
      // below code is for dropdown
      // Todo :: instead of length add type check
      const oldValue = values[name];
      this.toggleEnableDisable(oldValue, formElement, false);
    }
  };

  toggleEnableDisable = (value, formElement, disable) => {
    let disableValue = disable;
    const configuration = formElement?.enableDisable;

    const {
      props: { formConfig = [], changeValidationStructure },
      state: { values },
    } = this;

    if (!isArray(value)) return;

    value?.forEach((item) => {
      const targetConfiguration = configuration[item];

      if (targetConfiguration) {
        let _formConfig = [...formConfig];

        if (changeValidationStructure && typeof changeValidationStructure === "function") {
          _formConfig = changeValidationStructure(formConfig);
        }

        targetConfiguration?.forEach((targetConfig) => {
          const target = _formConfig.find((element) => element.name === targetConfig.id);

          if (disableValue === undefined && targetConfig.disable !== undefined) {
            disableValue = targetConfig.disable;
          }

          if (target && targetConfig.defaultValue !== undefined) {
            target.disabled = disableValue;

            if (disableValue || targetConfig?.forceUpdate) {
              values[targetConfig?.id] = targetConfig.defaultValue;
            }
          } else if (targetConfig.updateKey) {
            const newValue =
              targetConfig.type === "Array"
                ? [...targetConfig[targetConfig.updateKey]]
                : { ...targetConfig[targetConfig.updateKey] };
            target[targetConfig.updateKey] = newValue;
          } else if (target) {
            target[targetConfig.optionsKey]?.forEach((option) => {
              if (targetConfig.disableOptions.includes(option.value)) {
                const optionClone = option;
                optionClone.disable = disableValue;
              }
            });
          }
        });
      }
    });
  };

  deleteErrors = (formErrors, errors, name) => {
    if (!formErrors[name] && errors[name]) {
      const errorsClone = errors;
      delete errorsClone[name];
    }
  };

  /**
   *
   * @param {*} formErrors
   * @param {*} name
   * @param {*} formElement
   * @param {*} touched
   * in case of dependentFields validations error can be multiple
   * and we've to show error on every dependent field
   */
  markTouched = (formErrors, name, formElement, touched) => {
    const touchedClone = touched;

    if (
      Object.keys(formErrors || {})?.length > 1 &&
      formElement.validations.rules.dependentFields
    ) {
      Object.keys(formErrors || {})?.forEach((errorKey) => {
        touchedClone[errorKey] = true;
      });
    } else {
      touchedClone[name] = true;
    }
  };

  /**
   * Updated the final state
   */
  updateForm = (
    formErrors = {},
    values = {},
    name = "",
    callback = noop,
    formElement = {},
    ruleKey = "",
    event = {},
    showErrorOnSubmit = false,
    overRideErrors = false // created to handle formElement?.revalidation
  ) => {
    const {
      state: { errors = {}, touched },
      props: { showErrorOnLoad },
    } = this;

    /**
     * Below functionality is created for marking pattern validations
     */
    if (
      ruleKey &&
      formErrors?.[name] &&
      formElement?.validations?.onChange?.[ruleKey] &&
      event?.nativeEvent?.inputType !== "deleteContentBackward" &&
      event?.nativeEvent?.inputType !== "deleteContentForward" &&
      !showErrorOnSubmit
    ) {
      return;
    }

    this.deleteErrors(formErrors, errors, name);

    if (name) {
      this.markTouched(formErrors, name, formElement, touched);
    } else if ((showErrorOnLoad && Object.keys(formErrors).length) || showErrorOnSubmit) {
      Object.keys(formErrors)?.forEach((key) => {
        touched[key] = true;
      });

      if (showErrorOnSubmit && typeof showErrorOnSubmit === "function") {
        showErrorOnSubmit();
      }
    }

    /**
     * if a form element is using formElement?.revalidation,
     * we are setting overRideErrors to true
     * because we want to override errors object
     * To generate errors of newly showing components on UI
     * use this functionality
     */
    const newErrors = overRideErrors ? formErrors : { ...errors, ...formErrors };
    const newTouch = this.manageTouch(newErrors, touched);

    this.setState({ values, touched: newTouch, errors: newErrors }, () => {
      const {
        state: { values: updatedValues, errors: updatedErrors },
      } = this;

      if (callback && typeof callback === "function") {
        callback(updatedValues, updatedErrors);
      }
    });

    const {
      props: { isError },
    } = this;

    if (isError && typeof isError === "function") {
      const updateFormWrapper = overRideErrors
        ? Object.keys(formErrors).length > 0
        : Object.keys(formErrors).length > 0 || Object.keys(errors).length > 0;

      isError(updateFormWrapper);
    }
  };

  manageTouch = (newErrors, touched) => {
    const touchedClone = touched;
    Object.keys(touched).forEach((touch) => {
      if (!newErrors[touch]) {
        delete touchedClone[touch];
      }
    });

    return touchedClone;
  };

  /**
   *
   * @param {*} event
   * @param {*} formElement
   * function to handle blur event
   * using it for generating errors on blur
   */
  handleBlur = (event, formElement) => {
    this.handleChange(event, formElement);
  };

  /**
   *
   * Form elements creation code starts here
   */
  renderInputType = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <Input
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        className={formElement.className}
      />
    );
  };

  execCallback = (e, placement = "after") => {
    const formElement = e?.detail?.sourceData?.formElement;
    if (!formElement) return;
    if (
      formElement?.customEvent?.placement === placement &&
      typeof formElement?.customEvent?.callback === "function"
    ) {
      formElement?.customEvent?.callback();
    }
  };

  handleBackToSourceEvent = (event) => {
    const sourceEvent = event?.detail?.sourceEvent;
    const formElement = event?.detail?.sourceData?.formElement;
    this.execCallback(event, "before");
    this.handleChangeEvent(sourceEvent, formElement);
    this.execCallback(event, "after");

    const formElementCustomEvent = formElement?.customEvent;
    const resetInfo = formElementCustomEvent?.resetInfo;

    if (resetInfo && resetInfo?.key) {
      formElementCustomEvent[resetInfo.key] = resetInfo?.value;
    }
  };

  bindCustomEvent = () => {
    subscribe("back-to-source", this.handleBackToSourceEvent);
  };

  unbindCustomEvent = () => {
    unsubscribe("back-to-source", this.handleBackToSourceEvent);
  };

  renderDropDown = (formElement) => {
    const {
      state: { errors, values, touched },
      props: { resetCount },
    } = this;

    return (
      <DropDown
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        resetClicked={resetCount}
      />
    );
  };

  renderReadOnlyDropdown = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <ReadOnlyDropdown
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  renderRadioButton = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <RadioButtonGroup
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={formElement?.value ? formElement?.value : values[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  renderReadOnlyView = (formElement) => (
    <ReadOnlyView
      key={formElement.name}
      data={formElement.readOnlyData} // Pass the readOnlyData array from formElement
      isJson={formElement.isJson} // Pass the readOnlyData array from formElement
      handleDelete={formElement.handleDelete} // Pass the readOnlyData array from formElement
    />
  );

  renderInvoiceDeliveryType = (formElement) => {
    const {
      state: { errors, values },
    } = this;

    return (
      <InvoiceDeliveryType
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
      />
    );
  };

  renderInvoiceCustomer = (formElement) => {
    const {
      state: { errors, values, touched, initialValues },
      props: { resetCount, modifyLabelValue },
    } = this;

    return (
      <InvoiceCustomer
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        labelClass={formElement?.labelClass}
        v2={formElement?.v2}
        resetClicked={resetCount}
        modifyLabelValue={modifyLabelValue || formElement?.modifyLabelValue}
        /* we pass this prop because to reset the value of drop down to the initial values  */
        initialValue={initialValues[formElement?.name]}
      />
    );
  };

  renderCustomerInvoice = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <CustomerInvoice
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        values={values}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  renderLabel = (formElement) => (
    <Text
      {...formElement}
      key={formElement.name}
      label={formElement.label}
      text={formElement.placeHolder}
      className={formElement.className}
    />
  );

  renderFileUpload = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <UploadFile
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        values={values}
        touched={touched[formElement.name]}
      />
    );
  };

  renderCustomerLinkedAccId = (formElement) => {
    const {
      state: { errors, values, touched },
      props: { resetCount },
    } = this;

    return (
      <CustomerLinkedAccId
        key={formElement.name}
        noDataLabel={formElement?.noDataLabel}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        touched={touched[formElement.name]}
        resetClicked={resetCount}
      />
    );
  };

  renderTransferList = (formElement) => {
    const {
      state: { errors, values, touched },
      props: { resetCount },
    } = this;
    return (
      <TransferList
        key={formElement.name}
        noDataLabel={formElement?.noDataLabel}
        {...formElement}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement?.name]}
        resetClicked={resetCount}
        touched={touched[formElement?.name]}
      />
    );
  };

  renderCalendar = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <Calendar
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        values={values}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        autoApply
      />
    );
  };

  renderMonthCalendar = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <MonthCalendar
        key={formElement.name}
        {...formElement}
        error={errors[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        value={values[formElement.name]}
        values={values}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        autoApply
      />
    );
  };

  renderTextArea = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <TextArea
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  renderPartnerEmailSettings = (formElement) => {
    const {
      state: { errors, values },
    } = this;

    return (
      <PartnerEmailSettings
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        error={errors}
      />
    );
  };

  renderSmtpSettings = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <SmtpSettings
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  toggleInput = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <ToggleInput
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  toggleListSelector = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;

    return (
      <ListSelector
        key={formElement.name}
        {...formElement}
        values={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
        options={[]}
      />
    );
  };

  renderCheckbox = (formElement) => {
    const {
      state: { values },
    } = this;

    return (
      <Checkbox
        key={formElement.name}
        {...formElement}
        checked={values[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderChipMultiSelect = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <MultiChipSelector
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(handleParams) => this.handleChange(handleParams, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(blurParams) => this.handleBlur(blurParams, formElement)}
      />
    );
  };

  renderAutoResizeTextbox = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <AutoResizeTextbox
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(handleParams) => this.handleChange(handleParams, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(blurParams) => this.handleBlur(blurParams, formElement)}
      />
    );
  };

  renderCheckboxGroup = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <CheckboxGroup
        key={formElement.name}
        {...formElement}
        value={values[formElement.name]}
        handleChange={(handleParams) => this.handleChange(handleParams, formElement)}
        error={errors[formElement.name]}
        touched={touched[formElement.name]}
        onBlurHandler={(blurParams) => this.handleBlur(blurParams, formElement)}
      />
    );
  };

  renderHeaderComponent = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <MultiHeaderForm
        formElement={formElement}
        {...formElement}
        errors={errors}
        values={values}
        touched={touched}
        handleBlur={this.handleBlur}
        handleChange={this.handleChange}
      />
    );
  };

  renderMultiAdditionComponent = (formElement) => {
    const {
      state: { errors, values, touched },
      props: { formValues, resetCount },
    } = this;
    return (
      <MultiAdditionComponent
        formElement={formElement}
        errors={errors}
        values={values}
        formValues={formValues}
        touch={touched}
        onBlurHandler={this.handleBlur}
        handleChange={this.handleChange}
        resetClicked={resetCount}
      />
    );
  };

  /* */

  renderConfigurationTable = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <EditableTable
        key={formElement.name}
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderConfigurationTableWithTabs = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <EditableTableWithTabs
        key={formElement.name}
        {...formElement}
        value={values?.configureWithTabs || values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderInfoBox = (formElement) => <InfoBox {...formElement} />;

  renderCommitmentTable = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <ConfigureCommitment
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderList = (formElement) => <List {...formElement} />;

  // eslint-disable-next-line react/no-danger
  renderHTML = (formElement) => <RenderHTML {...formElement} />;

  renderListingWithPagination = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <PaginationTable
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderAutoCompleteInput = (formElement) => {
    const {
      state: { errors, values, touched },
    } = this;
    return (
      <AutoCompleteInput
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        touched={touched[formElement.name]}
        onBlurHandler={(event) => this.handleBlur(event, formElement)}
      />
    );
  };

  renderOptionaInput = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <OptionInputField
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderCreditOrdering = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <CreditsOrdering
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderSelectionTable = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <SelectionTable
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderSegmentedList = (formElement) => {
    const {
      state: { errors, values },
    } = this;
    return (
      <SectionedItems
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
      />
    );
  };

  renderDynamicTextFields = (formElement) => {
    const {
      state: { errors, values, touched, initialValues },
      props: { resetCount },
    } = this;

    return (
      <DynamicTextFields
        key={formElement.name}
        {...formElement}
        value={values[formElement?.name]}
        error={errors[formElement?.name]}
        touched={touched[formElement.name]}
        handleChange={(event) => this.handleChange(event, formElement)}
        // onBlurHandler={(event) => this.handleBlur(event, formElement)}
        className={formElement.className}
        initialValue={initialValues[formElement?.name]}
        resetCount={resetCount}
      />
    );
  };

  renderInputTypes = (formElement) => {
    switch (formElement?.type) {
      case "text":
      case "number":
      case "password":
        return this.renderInputType(formElement);
      case "smtp-settings-type":
        return this.renderSmtpSettings(formElement);
      case "toggleInput":
        return this.toggleInput(formElement);
      case "listSelector":
        return this.toggleListSelector(formElement);
      case "transfer-List":
        return this.renderTransferList(formElement);
      case "configurableTable":
        return this.renderConfigurationTable(formElement);
      case "commitmentTable":
        return this.renderCommitmentTable(formElement);
      case "configurableTableWithTabs":
        return this.renderConfigurationTableWithTabs(formElement);
      case "infoBox":
        return this.renderInfoBox(formElement);
      case "monthCalender":
        return this.renderMonthCalendar(formElement);
      case "CreditsOrdering":
        return this.renderCreditOrdering(formElement);
      case "paginationListing":
        return this.renderListingWithPagination(formElement);
      case "optionaInput":
        return this.renderOptionaInput(formElement);
      case "selectionTable":
        return this.renderSelectionTable(formElement);
      case "autoCompleteInput":
        return this.renderAutoCompleteInput(formElement);
      default:
        return null;
    }
  };
  /**
   *
   * Form elements creation code ends here
   */

  render() {
    const {
      props: { formConfig, children, wrapperStyle, information, hasWritePermision },
    } = this;
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`${!hasWritePermision && "read-only-form"}`}
        tabIndex={`${hasWritePermision && "-1"}`}
      >
        <div className="flex createForm">
          <div className={wrapperStyle}>
            {formConfig?.map((formElement) => {
              if (formElement?.hidden) {
                return null;
              }
              switch (formElement?.type) {
                case "select":
                  return this.renderDropDown(formElement);
                case "readonly-view":
                  return this.renderReadOnlyView(formElement);
                case "select-readonly":
                  return this.renderReadOnlyDropdown(formElement);
                case "radio":
                  return this.renderRadioButton(formElement);
                case "invoice-delivery-type":
                  return this.renderInvoiceDeliveryType(formElement);
                case "invoice-customer-input-type":
                  return this.renderInvoiceCustomer(formElement);
                case "Label":
                  return this.renderLabel(formElement);
                case "file":
                  return this.renderFileUpload(formElement);
                case "linked-AccId-input-type":
                  return this.renderCustomerLinkedAccId(formElement);
                case "calender":
                  return this.renderCalendar(formElement);
                case "checkbox":
                  return this.renderCheckbox(formElement);
                case "textarea":
                  return this.renderTextArea(formElement);
                case "partner-email-settings-type":
                  return this.renderPartnerEmailSettings(formElement);
                case "multiselect-selection":
                  return this.renderCustomerInvoice(formElement);
                case "mult-chip-select":
                  return this.renderChipMultiSelect(formElement);
                case "auto-resize-textbox":
                  return this.renderAutoResizeTextbox(formElement);
                case "chekbox_group":
                  return this.renderCheckboxGroup(formElement);
                case "headerComponent":
                  return this.renderHeaderComponent(formElement);
                case "MultiAdditionComponent":
                  return this.renderMultiAdditionComponent(formElement);
                case "list":
                  return this.renderList(formElement);
                case "customHTML":
                  return this.renderHTML(formElement);
                case "segmented-list":
                  return this.renderSegmentedList(formElement);
                case "dynamicTextFields":
                  return this.renderDynamicTextFields(formElement);
                default:
                  return this.renderInputTypes(formElement);
              }
            })}
          </div>
          {information && (
            <div
              style={{ boxShadow: "-7px 14px 38px #343A4015" }}
              className="information p-[15px] w-fit flex border-l border-solid border-[#E6E6E6]"
            >
              {information()}
            </div>
          )}
        </div>
        {children}
      </form>
    );
  }
}

export default Form;
