import React from "react";
import { ReactComponent as Cluster } from "../Assets/cluster.svg";
import { ReactComponent as CpuCost } from "../Assets/cpuCost.svg";
import { ReactComponent as MemoryCost } from "../Assets/memoryCost.svg";
import { ReactComponent as Nodes } from "../Assets/nodes.svg";
import { ReactComponent as Pods } from "../Assets/pods.svg";
import { ReactComponent as ActiveDaily } from "../Assets/daily-active.svg";
import { ReactComponent as InactiveActiveDaily } from "../Assets/daily-inactive.svg";
import { ReactComponent as ActiveMonthly } from "../Assets/monthly-active.svg";
import { ReactComponent as InactiveMonthly } from "../Assets/monthly-inactive.svg";
import { ReactComponent as StackGraphActive } from "../Assets/stack-graph-active.svg";
import { ReactComponent as StackGraphInActive } from "../Assets/stack-graph-inactive.svg";
import { ReactComponent as LineGraphActive } from "../Assets/line-graph-active.svg";
import { ReactComponent as LineGraphInActive } from "../Assets/line-graph-inactive.svg";
import { ReactComponent as BarChartActive } from "../Assets/bar-graph-active.svg";
import { ReactComponent as BarChartInActive } from "../Assets/bar-graph-inactive.svg";
import cpuCostBackground from "../Assets/cpuCostBackground.svg";
import memoryCostBackground from "../Assets/memoryCostBackground.svg";
import nodesBackground from "../Assets/nodesBackground.svg";
import podsBackground from "../Assets/podsBackground.svg";
import clusterBackground from "../Assets/clusterBackground.svg";

// eslint-disable-next-line import/prefer-default-export
export const SUMMARY_CARDS_IMAGES = [
  {
    icon: <CpuCost />,
    backgroundImage: cpuCostBackground,
  },
  {
    icon: <MemoryCost />,
    backgroundImage: memoryCostBackground,
  },
  {
    icon: <Cluster />,
    backgroundImage: clusterBackground,
  },
  {
    icon: <Nodes />,
    backgroundImage: nodesBackground,
  },
  {
    icon: <Pods />,
    backgroundImage: podsBackground,
  },
];

export const DAILY_MONTHLY_TOGGLE = [
  {
    label: "Daily",
    value: "daily",
    activeIcon: <ActiveDaily />,
    inactiveIcon: <InactiveActiveDaily />,
  },
  {
    label: "Monthly",
    value: "monthly",
    activeIcon: <ActiveMonthly />,
    inactiveIcon: <InactiveMonthly />,
  },
];

export const IMAGES_LIST = [
  {
    text: "groupChart",
    activeIcon: <BarChartActive />,
    inactiveIcon: <BarChartInActive />,
    label: "Bar",
  },
  {
    text: "stackedcolumn2d",
    activeIcon: <StackGraphActive />,
    inactiveIcon: <StackGraphInActive />,
    label: "Stack",
  },
  {
    text: "multipleLineChart",
    activeIcon: <LineGraphActive />,
    inactiveIcon: <LineGraphInActive />,
    label: "Line",
  },
];
export const PALLETE_COLORS_CUDOS = [
  "#8ECFFF",
  "#60C0A6",
  "#F6B273",
  "#FBD064",
  "#907BE2",
  "#2A86FF",
  "#EA6AA7",
  "#4E8649",
  "#4184A1",
];
export const SELECTED_TAB = "EKS";

const headerClass =
  "!bg-[#0A3CA2] text-[#fff] px-[8px] py-[5px] cursor-pointer border-r border-solid border-[#E0F1FF] text-left";
const bodyClass = "py-[4px] px-1.5 text-[11px] text-left";

const commonHeaders = { bodyClass, headerClass, defaultValue: "" };
const commonHeadersRightAlign = {
  bodyClass: `${bodyClass} !text-right`,
  headerClass: `${headerClass} !text-right`,
  defaultValue: "",
};
export const TOP_20_INSTANCE_HEADERS = [
  {
    value: "Cluster Name",
    key: "cluster_name",
    ...commonHeaders,
  },
  {
    value: "Namespace",
    key: "namespace",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "Node Instance Type",
    key: "node_instance_type",
    ...commonHeaders,
  },
  {
    value: "Node Purchase Option",
    key: "node_purchase_option",
    ...commonHeaders,
  },
  {
    value: "Used CPU Cost",
    key: "used_cpu_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused CPU Cost",
    key: "unused_cpu_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Used Memory Cost",
    key: "used_memory_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Unused Memory Cost",
    key: "unused_memory_cost",
    footerClass: "text-right",
    ...commonHeadersRightAlign,
  },
  {
    value: "Total Cost",
    ...commonHeadersRightAlign,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const TOP_20_INSTANCE_HEADERS_ELASTICACHE = [
  {
    value: "Account ID",
    key: "linked_account_id",
    ...commonHeaders,
  },
  {
    value: "Instance Name",
    key: "instance_name",
    ...commonHeaders,
  },
  {
    value: "Region",
    key: "region",
    ...commonHeaders,
  },
  {
    value: "DB Engine",
    key: "cache_engine",
    ...commonHeaders,
  },
  {
    value: "Instance Type",
    key: "instance_type",
    ...commonHeaders,
  },
  {
    value: "Pricing Type",
    key: "pricing_type",
    ...commonHeaders,
  },
  {
    value: "Usage Hours",
    key: "usage_hours",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    footerClass: "text-right",
    headerClass: `${commonHeaders.headerClass} text-right`,
    currencyRequired: false,
  },
  {
    value: "Total Cost",
    ...commonHeaders,
    bodyClass: "py-[4px] px-[8px] text-[11px] new-total-theme text-right",
    headerClass: `${commonHeaders.headerClass} text-right`,
    key: "cost",
    decimalPlaces: 0,
  },
];

export const doughnutPaletteColors = "4592FF,FFA3AB";

export const SUMMARY_CARDS_CONSTANT = {
  eks: [
    {
      key: "Total CPU Cost",
      label: "Total CPU Cost",
      value: [
        {
          label: "Used",
          key: "Used",
        },
        {
          key: "Unused",
          label: "Unused",
        },
      ],
    },
    {
      key: "Total Memory Cost",
      label: "Total Memory Cost",
      value: [
        {
          key: "Used",
          label: "Used",
        },
        {
          key: "Unused",
          label: "Unused",
        },
      ],
    },
    {
      key: "Total Cluster",
      label: "Total Cluster",
      value: [
        {
          key: "Total Cluster",
          label: "Total Cluster",
        },
      ],
    },
    {
      key: "Total Nodes",
      label: "Total Nodes",
      value: [
        {
          key: "Total Nodes",
          label: "Total Nodes",
        },
      ],
    },
    {
      key: "Total Pods",
      label: "Total Pod",
      value: [
        {
          key: "Total Pods",
          label: "Total Pods",
        },
      ],
    },
  ],
  elasticache: [
    {
      key: "OnDemand",
      label: "OnDemand",
      value: [
        {
          key: "Graviton",
          label: "Graviton",
        },
        {
          key: "Intel",
          label: "Intel",
        },
      ],
    },
    {
      key: "Reserved",
      label: "Reserved",
      value: [
        {
          key: "Graviton",
          label: "Graviton",
        },
        {
          key: "Intel",
          label: "Intel",
        },
      ],
    },
  ],
};
