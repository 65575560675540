import { getEncryptedData, getLocalStorageUserData } from "../../../../Utils/commonUtils";

// console.log(getLocalStorageUserData());
export const getTunerLoginPayload = () => {
  const userData = getLocalStorageUserData();

  const payload = {
    jwt: userData?.tokens?.accessToken,
    mavId: userData?.tokens?.mavId,
    moduleId: userData?.tokens?.moduleId,
    partnerId: userData?.tokens?.partnerId,
    customerId: userData?.currentUser?.id || userData?.tokens?.customerId,
  };

  return {
    payload: getEncryptedData(payload),
    userData,
  };
};

/**
 * below function will set the token
 * on browser tuner extension after
 * user have successfully logged-in
 */
export const setTunerLoginToken = () => {
  try {
    const { payload, userData } = getTunerLoginPayload();

    if (userData?.currentUser?.type === "CUSTOMER") {
      console.log("firing login event", userData);
      window.postMessage({ type: "CK_TUNER_LOGIN", payload }, "*");
    }
  } catch (error) {
    console.info(error);
  }
};

/**
 * below function will make the
 * user logout on browser tuner extension
 */
export const removeTunerToken = () => {
  try {
    console.log("removing token");
    window.postMessage({ type: "CK_TUNER_LOGOUT" }, "*");
  } catch (error) {
    console.info(error);
  }
};

/**
 * below function will set the token on
 * browser tuner extension when the user
 * is already logged-in in CK website.
 * This function will also work when
 * user is switched to tuner customer
 */
export const setTunerToken = () => {
  try {
    const { payload, userData } = getTunerLoginPayload();

    if (userData?.tokens?.moduleName === "tuner" && userData?.currentUser?.type === "CUSTOMER") {
      console.log("setting token");
      window.postMessage({ type: "CK_TUNER_TOKEN", payload }, "*");
    }
  } catch (error) {
    console.info(error);
  }
};
