import React, { useRef, useState } from "react";
import FusionChart from "../../../../Common/fusionChart";
import SingleLevelTable from "../singleLevelTable/index";
import TabSelector from "../../../../Common/graph-table-view/TabSelector";
import Loader from "../../../../../Common/Loader";
import RadioButtonGroup from "../../../../../FormConfig/Form/Components/RadioButtonGroup";
import ExcelDownload from "../../../../../Common/ExcelDownload";
import ToggleBtnGroup from "../../../../../FormConfig/Form/Components/ToggleBtnGroup";
import { ReactComponent as MoreIcon } from "../../../../../../Assets/icons/menu.svg";
import { ReactComponent as ExpandIcon } from "../../../../../../Assets/icons/maximize-arrow.svg";
import { handleKeyPress } from "../../EKS/common/helper";
import useDetectOutsideClick from "../../../../../../CustomHooks/useDetectOutsideClick";
import TableModal from "../../EKS/component/TableModal";
import ActionsDialog from "../../../../../Common/Modal/ActionsDialog";
import { downloadExcel } from "../../../../../../Utils/commonUtils";

// eslint-disable-next-line max-lines-per-function
function Graph(props) {
  const {
    graph: {
      heading,
      xAxisName,
      yAxisName,
      tabs,
      type,
      graphData,
      imagesList,
      graphtableData,
      graphTableHeader,
      graphTableFooter = [],
      legendPosition,
      showLegend,
      legendRight = false,
      legendCaption = "",
      graphLabel = "",
      list,
      calendarType = "",
      showLabels,
      name,
      toggleType = "radio",
      active = "",
      prefix,
      showMoreIcon = false,
      showGraphExpandIcon = false,
      palettecolors,
      tooltip,
      height,
      numberPrefix,
      numVDivLines,
      plotSpacePercent,
      hideScroll = false,
      maxLabelHeight,
      decimals,
      chartBottomMargin,
      legendIconSides,
      divLineDashed,
      divLineColor,
      xaxislinecolor,
      yaxislinecolor,
      labelFontColor,
      labelFontSize,
      rotateLabels,
      yAxisValueFontColor,
      yAxisValueFontSize,
      width,
      labelStep,
      numbersuffix,
      anchorRadius,
    },
    id,
    show = false,
    dataTransferHeading,
    showExcelBtn = false,
    showAxisIcons = {
      xAxis: false,
      yAxis: false,
    },
    wrapperClass = "",
    onExcelDownload = () => {},
    graphLoading = false,
    showSortingOnTotal = false,
    graphBackgroundColor,
    isLoading = false,
    selectedService,
    activeGranularity,
  } = props;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showGraphModal, setShowGraphModal] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);

  const ref = useRef();
  const ImageListSelector = (selectedProp) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedProp);
  };
  const onRadioChange = (selectedProp) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedProp, "calendarType");
  };

  const toggleBtnClick = (selectedBtn) => {
    const { onGraphChange } = props;
    onGraphChange(name, selectedBtn, "costUsageType");
  };

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    setShowMoreOptions(false);
  });

  const renderList = () => {
    switch (toggleType) {
      case "radio":
        return (
          <RadioButtonGroup
            key="monthlyDaily"
            options={list}
            handleChange={(e) => onRadioChange(e.target.value)}
            value="Daily"
            childClassName="graphHeaderRadioChildClass"
            className="graphHeaderRadio"
          />
        );
      case "toggle":
        return (
          <ToggleBtnGroup
            buttonArray={list}
            toggleBtnClick={toggleBtnClick}
            active={active}
            className="renderToggleClass"
          />
        );
      default:
        return null;
    }
  };

  const renderFusionChart = (updatedWidth = width) =>
    !isLoading ? (
      <div className={type || ""}>
        {graphData?.dataset?.length ||
        graphData?.data ||
        (type === "column2d" && graphData.length) ? (
          <FusionChart
            xAxisName={showAxisIcons?.xAxis ? xAxisName : ""}
            yAxisName={showAxisIcons?.yAxis ? yAxisName : ""}
            graphData={graphData}
            chartType={type}
            legendPosition={legendPosition}
            showLegend={showLegend}
            show={show}
            legendRight={legendRight}
            legendCaption={legendCaption}
            graphLabel={graphLabel}
            legendBorderThickness="700px"
            legendIconSides={legendIconSides}
            drawCustomLegend={1}
            xAxisNameFontBold={showAxisIcons?.xAxisNameFontBold}
            showLabels={showLabels}
            prefix={prefix}
            plotToolText={tooltip}
            palettecolors={palettecolors}
            bgColor={graphBackgroundColor}
            height={height}
            numberPrefix={numberPrefix}
            hideScroll={hideScroll}
            numVDivLines={numVDivLines}
            plotSpacePercent={plotSpacePercent}
            maxLabelHeight={maxLabelHeight}
            decimals={decimals}
            chartBottomMargin={chartBottomMargin}
            divLineDashed={divLineDashed}
            divLineColor={divLineColor}
            xaxislinecolor={xaxislinecolor}
            yaxislinecolor={yaxislinecolor}
            labelFontColor={labelFontColor}
            labelFontSize={labelFontSize}
            rotateLabels={rotateLabels}
            yAxisValueFontColor={yAxisValueFontColor}
            yAxisValueFontSize={yAxisValueFontSize}
            width={updatedWidth}
            labelStep={labelStep}
            numbersuffix={numbersuffix}
            anchorRadius={anchorRadius}
          />
        ) : (
          <div className="no_data">
            <h5>No Data Available</h5>
          </div>
        )}
      </div>
    ) : null;

  const getGraphExpandIcon = () =>
    showGraphExpandIcon ? (
      <div
        role="button"
        tabIndex={0}
        className="p-[4px] mr-[10px] bg-[#F0F2F6] border border-transparent rounded-[3px] cursor-pointer"
        onClick={() => setShowGraphModal(true)}
        onKeyPress={(e) => {
          handleKeyPress(e, true, setShowGraphModal);
        }}
      >
        <ExpandIcon className="expand-icon" />
      </div>
    ) : null;

  const exportDownload = async (service, granularity) => {
    setExcelLoading(true);
    try {
      const response = await onExcelDownload(service, granularity?.toUpperCase());
      downloadExcel(response);
    } finally {
      setExcelLoading(false);
    }
  };

  const getExcelButton = () => (
    <div className="excel-button ml-[16px]">
      <ExcelDownload
        excelLoading={excelLoading}
        downloadHandler={() => {
          if (selectedService && activeGranularity) {
            exportDownload(selectedService, activeGranularity);
          } else {
            onExcelDownload(name);
          }
        }}
        hasData={!(graphData?.dataset?.length || graphData?.data)}
      />
    </div>
  );

  return (
    <div className={`billing_summary_wrapper s3GraphCont ${wrapperClass}`}>
      <div className="billing_summary">
        <div className="Graph_table_wrapper box-styling">
          <div
            className={
              tabs
                ? "Graph_table_heading border-bottom-grey withTabs"
                : "Graph_table_heading border-bottom-grey "
            }
          >
            <div className="d_flex heading_wrapper">
              <h3 className={`heading main-text-bold ${dataTransferHeading}`}>
                {!!heading && heading}
              </h3>
            </div>
            {!!tabs && (
              <div className="d_flex items-center">
                <div className="graphHeaderRadioParent">
                  {calendarType?.text && (
                    <div className={calendarType?.class}>{calendarType?.text}</div>
                  )}
                  {list?.length ? renderList() : null}
                </div>
                <TabSelector
                  type="cudos-images"
                  imagesList={imagesList}
                  selected={type}
                  onSelect={ImageListSelector}
                  id={id}
                />
                {showExcelBtn && getExcelButton()}
              </div>
            )}
            {showMoreIcon ? (
              <div
                className={`showMoreIcon flex h-[24] ${showMoreOptions ? "activeIcon" : ""}`}
                ref={ref}
              >
                {getGraphExpandIcon()}

                <MoreIcon
                  onClick={() => setShowMoreOptions(!showMoreOptions)}
                  className={`more-icon cursor-pointer ${showMoreOptions ? "active" : ""}`}
                />
                {showMoreOptions && (
                  <div className="more_container">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setShowModal(true);
                      }}
                      onKeyPress={(event) => {
                        handleKeyPress(event, true, setShowModal);
                      }}
                      className="option text-[12px] py-[6px] px-[10px] font-medium text-[#2B2B2B] mb-[3px] leading-none"
                    >
                      View Table
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (selectedService && activeGranularity) {
                          exportDownload(selectedService, activeGranularity);
                        } else {
                          onExcelDownload(name);
                        }
                        setShowMoreOptions(false);
                      }}
                      onKeyPress={(event) => {
                        if (selectedService && activeGranularity) {
                          handleKeyPress(event, selectedService, exportDownload, activeGranularity);
                        } else {
                          handleKeyPress(event, name, onExcelDownload);
                        }
                        setShowMoreOptions(false);
                      }}
                      className="option text-[12px] py-[6px] px-[10px] font-medium text-[#2B2B2B] leading-none"
                    >
                      Export Excel
                    </div>
                  </div>
                )}
                {showModal && (
                  <TableModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    graphTableHeader={graphTableHeader}
                    graphtableData={graphtableData}
                    graphTableFooter={graphTableFooter}
                    heading={heading}
                    downloadSheetButton={getExcelButton}
                  />
                )}
              </div>
            ) : null}
          </div>
          <div className="data_renderer">
            {graphLoading ? (
              <Loader />
            ) : (
              <div>
                {type === "table" ? (
                  <div className="table_container_wrapper">
                    {graphtableData?.length ? (
                      <SingleLevelTable
                        headings={graphTableHeader}
                        data={graphtableData}
                        sortedIcon
                        footer={graphTableFooter}
                        newFooter={graphTableFooter}
                        heading={`table_${id}`}
                        V2
                        lastColumnSticky
                        showSortingOnTotal={showSortingOnTotal}
                      />
                    ) : (
                      <div className="no_data">
                        <h5>No Data Available</h5>
                      </div>
                    )}
                  </div>
                ) : (
                  renderFusionChart()
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionsDialog
        open={showGraphModal}
        data={{
          config: {
            heading,
            type: "Custom",
            actions: false,
            dialogClass: "modal_table_container graph_modal",
            body: renderFusionChart("965"),
          },
        }}
        handleClose={() => setShowGraphModal(false)}
        showCancelBtn={false}
        showCloseIcon
      />
    </div>
  );
}

export default Graph;
