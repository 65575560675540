import React from "react";
import { Link } from "react-router-dom";
import UtilizationCardV2 from "./Reservations/utilizationCard/utilizationCardV2";
import ComponentHeader from "../Common/ComponentHeader";
import InfoBox from "../Common/InfoBox";

import { ReactComponent as SackDollarIcon } from "../../../Assets/icons/utilizationCardIcon2.svg";
import { ReactComponent as VectorDollarIcon } from "../../../Assets/icons/utilizationCardIcon1.svg";
import { TabV2, TabsV2 } from "../Common/TabsV2";
import { noop } from "../../../Utils/commonUtils";

export const headerComponent = (breadcrumsData, infoMessage, headerName) => (
  <div className="header_V2style !border-b-0">
    <ComponentHeader
      breadcrumsData={breadcrumsData}
      headerName={headerName}
      className="mt-[15px] mb-[6px]"
    />
    <InfoBox customClass="mr-[12px] mt-[44px] mb-[10px]" message={infoMessage} />
  </div>
);

export function UtilizationCards(props) {
  const { reservationUtilizationData, demandCost, percentageData, headerForCards } = props;
  return (
    <div className="utilization_banner_right V2">
      <UtilizationCardV2
        header={headerForCards}
        percentage={
          !!reservationUtilizationData && reservationUtilizationData?.length > 0
            ? percentageData
            : 0
        }
        progressbar
        cardIcon={<VectorDollarIcon />}
      />
      <UtilizationCardV2
        header="Total On-Demand Cost"
        cost={
          !!reservationUtilizationData && reservationUtilizationData?.length > 0
            ? demandCost?.toFixed(0)
            : 0
        }
        progressbar={false}
        cardIcon={<SackDollarIcon />}
      />
    </div>
  );
}

// making it final tabswrapper for lens
// for new theme use v3 in wrapperClass
export function TabsWrapper(
  isLoading,
  tabs = [],
  selected = "",
  tabClicked = noop,
  pathName = "/dashboard",
  wrapperClass = ""
) {
  return (
    <TabsV2 disabled={isLoading} wrapperClass={wrapperClass}>
      {tabs.map((item, index) => (
        <Link
          to={{
            pathname: pathName,
            search: `selected=${item}`,
          }}
        >
          <TabV2
            onClick={(e) => {
              tabClicked(e, item);
            }}
            selectedTab={selected}
            tab={item}
            index={index}
            tabs={tabs}
          >
            {item}
          </TabV2>
        </Link>
      ))}
    </TabsV2>
  );
}

export const getDashboardByPath = (menuList = [], titleName = "", menu = []) => {
  menuList?.forEach((item) => {
    if (item?.name === titleName) {
      menu.push(item);
    }
    if (item?.dashboardServices?.length) {
      getDashboardByPath(item?.dashboardServices, titleName, menu);
    }
  });
  return menu;
};
